import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRGuidesLightConesPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Character progression</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_charprogression.png"
            alt="Character progression"
          />
        </div>
        <div className="page-details">
          <h1>Character progression</h1>
          <h2>
            The guide explaining the character progression in Honkai: Star Rail.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Character Progression" />
        <StaticImage
          src="../../../images/starrail/generic/guide_character.webp"
          alt="Characters"
        />
        <p>
          Upgrading your Characters in Honkai: Star Rail will enhance their
          combat capabilities, allowing you to challenge and overcome the more
          difficult content that the game offers.
        </p>
        <p>
          The main ways for a Character to grow stronger in Honkai: Star Rail
          are:
        </p>
        <ul>
          <li>Increasing the Character level</li>
          <li>Upgrading the Character’s Traces</li>
          <li>Unlocking the Character’s Eidolons</li>
          <li>Equipping Light Cones </li>
          <li>Equipping Relics and Planetary Ornaments</li>
        </ul>
        <h5>Character Level and Ascension</h5>
        <p>
          Each Character in Honkai: Star Rail has a Character Level{' '}
          <strong>ranging from 1 to 80</strong> and gains additional stats as
          they level up.
        </p>
        <p>
          Once a Character reaches certain level <strong>milestones</strong>,
          they will need to be ascended in order to increase their level
          further. Characters start with a base max Level of 20. Each time you
          ascend a Character, their level cap will increase by 10 up to a
          maximum of 80. To ascend a Character, you will need to gather
          materials from the Stagnant Shadow Bosses and enemies in the
          overworld.
        </p>
        <p>
          Also, note that your Trailblaze Level (Account Level) will also
          determine how many times you can ascend a Character. Even if you have
          all of the required ascension materials, you will not be able to
          ascend a Character past a certain Level unless your account is also of
          the appropriate Trailblaze Level.
        </p>
        <SectionHeader title="Traces" />
        <StaticImage
          src="../../../images/starrail/generic/guide_traces.webp"
          alt="Characters"
        />
        <p>
          Traces are a skill upgrade tree that each Character has. Unlocking and
          upgrading the various nodes in a Character’s Trace will provide them
          with stat bonuses, extra passive abilities, and enhancements to their
          5 Skills.
        </p>
        <p>
          Traces can be upgraded by using materials found in the Crimson Calyx,
          as mission rewards, or purchased in the shop. Also, note that the
          ability to upgrade Traces is tied to your Character’s Ascension, so
          you will need to level your Character first before being able to
          upgrade their Traces.
        </p>
        <SectionHeader title="Eidolons" />
        <StaticImage
          src="../../../images/starrail/generic/guide_eidolon.webp"
          alt="Characters"
        />
        <p>
          Eidolons enhance Character’s abilities or provide them with new
          passive bonuses.
        </p>
        <p>
          Each Character has 6 Eidolon upgrades with each Eidolon upgrade
          providing a different bonus.
        </p>
        <p>
          Eidolon upgrades can be unlocked by obtaining duplicate copies of the
          Character from Warps (the gacha system) or through in-game missions
          and events. The only exception to this is the Main Character. You can
          obtain their Eidolon upgrades via in-game missions and Trailblaze
          Level (Account Level) rewards.
        </p>
        <SectionHeader title="Light Cones" />
        <StaticImage
          src="../../../images/starrail/generic/guide_cone.webp"
          alt="Characters"
        />
        <p>
          Light Cones are a type of equipment that all Characters can equip.
          They are the{' '}
          <strong>
            equivalent of weapons in other games and provide stat bonuses to the
            equipped Character
          </strong>
          .
        </p>
        <p>
          Check more information about Light Cones in our guide dedicated to
          them:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Light Cones"
            link="/star-rail/guides/light-cones"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_lightcone.png"
                alt="Light Cones"
              />
            }
          />
        </Row>
        <SectionHeader title="Relics" />
        <StaticImage
          src="../../../images/starrail/generic/guide_relics.webp"
          alt="Characters"
        />
        <p>
          Relics are the other type of equipment in this game and serve the
          function of armor and accessories in a traditional RPG game. Equipping
          Relics to your Character will provide them with a variety of stat
          increases from HP to ATK to Speed. Relics will provide your Characters
          with the main bulk of their stat increases.
        </p>
        <p>
          Check more information about Relics in our guide dedicated to them:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Relics"
            link="/star-rail/guides/relics"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_relicinfo.png"
                alt="Relics"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesLightConesPage;

export const Head: React.FC = () => (
  <Seo
    title="Character progression | Honkai: Star Rail | Prydwen Institute"
    description="The guide explaining the character progression in Honkai: Star Rail."
  />
);
